//
@use '@angular/material' as mat;
@use '@angular/cdk' as cdk;
@use "sass:map";
@import '@angular/material/theming';
// Material Angular Config@use '@angular/cdk' as cdk;
//
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
//@import "node_modules/@angular/material/theming";

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();
/*
$my_accent_palette: (
	50 : #fff3e4,
	100 : #ffe1bc,
	200 : #ffcd90,
	300 : #ffb964,
	400 : #ffaa42,
	500 : #ff9b21,
	600 : #ff931d,
	700 : #ff8918,
	800 : #ff7f14,
	900 : #ff6d0b,
	A100 : #ff9b21,
	A200 : #ffe1bc,
	A400 : #ff7f14,
	A700 : #ff9b21,
	contrast: (
		50 : #000000,
		100 : #000000,
		200 : #000000,
		300 : #000000,
		400 : #000000,
		500 : #000000,
		600 : #000000,
		700 : #000000,
		800 : #ffffff,
		900 : #ffffff,
		A100 : #000000,
		A200 : #000000,
		A400 : #ffffff,
		A700 : #000000,
	)
);

$my_primary_palette: (
	50 : #e9eff6,
	100 : #c8d7e8,
	200 : #a4bdd9,
	300 : #7fa3ca,
	400 : #638fbe,
	500 : #487bb3,
	600 : #4173ac,
	700 : #3868a3,
	800 : #305e9a,
	900 : #214b8b,
	A100 : #487bb3,
	A200 : #c8d7e8,
	A400 : #305e9a,
	A700 : #487bb3,
	contrast: (
		50 : #000000,
		100 : #000000,
		200 : #000000,
		300 : #000000,
		400 : #ffffff,
		500 : #ffffff,
		600 : #ffffff,
		700 : #ffffff,
		800 : #ffffff,
		900 : #ffffff,
		A100 : #ffffff,
		A200 : #000000,
		A400 : #000000,
		A700 : #ffffff,
	)
);

$my_warn_palette: (
	50 : #f9e6e6,
	100 : #efc1c1,
	200 : #e59898,
	300 : #db6e6e,
	400 : #d34f4f,
	500 : #cb3030,
	600 : #c62b2b,
	700 : #be2424,
	800 : #b81e1e,
	900 : #ac1313,
	A100 : #cb3030,
	A200 : #efc1c1,
	A400 : #b81e1e,
	A700 : #cb3030,
	contrast: (
		50 : #000000,
		100 : #000000,
		200 : #000000,
		300 : #000000,
		400 : #ffffff,
		500 : #ffffff,
		600 : #ffffff,
		700 : #ffffff,
		800 : #ffffff,
		900 : #ffffff,
		A100 : #ffffff,
		A200 : #000000,
		A400 : #ffffff,
		A700 : #ffffff,
	)
);





$mat-primary: 	mat.define-palette($my_primary_palette, A100, A200, A400,A700);
$mat-accent: mat.define-palette($my_accent_palette, A100, A200, A400, A700);
$mat-warn: mat.define-palette($my_warn_palette, A100, A200, A400, A700);
*/

//mat-palette($base-palette, $default: 500, $lighter: 100, $darker: 700)

$mat-primary: (
	100: lighten(kt-get($kt-state-colors, brand, base), 8%),
	500: kt-get($kt-state-colors, brand, base),
	700: darken(kt-get($kt-state-colors, brand, base), 8%),
	contrast: (
		100: $dark-primary-text,
		500: $light-primary-text,
		700: $light-primary-text
	)
);

$mat-accent: (
	100: lighten(kt-get($kt-state-colors, accent, base), 8%),
	500: kt-get($kt-state-colors, accent, base),
	700: darken(kt-get($kt-state-colors, accent, base), 8%),
	//100: lighten(kt-get($kt-state-colors, primary, base), 8%),
	//500: kt-get($kt-state-colors, primary, base),
	//700: darken(kt-get($kt-state-colors, primary, base), 8%),
	contrast: (
		100: $dark-primary-text,
		500: $light-primary-text,
		700: $light-primary-text
	)
);

$mat-warn: (
	100: lighten(kt-get($kt-state-colors, danger, base), 8%),
	500: kt-get($kt-state-colors, danger, base),
	700: darken(kt-get($kt-state-colors, danger, base), 8%),
	contrast: (
		100: $dark-primary-text,
		500: $light-primary-text,
		700: $light-primary-text
	)
);

$angular-app-primary: mat.define-palette($mat-primary, 500, 100, 700);
$angular-app-accent: mat.define-palette($mat-accent, 500, 100, 700);
$angular-app-warn: mat.define-palette($mat-warn, 500, 100, 700); // The warn palette is optional (defaults to red).

// Create the theme object (a Sass map containing all of the palettes).
$angular-app-theme: (
	primary: $angular-app-primary,
	accent: $angular-app-accent,
	warn: $angular-app-warn,
	is-dark: false,
	foreground: (
		base: black,
		divider: kt-base-color(grey, 4),
		dividers: $dark-dividers,
		disabled: $dark-disabled-text,
		disabled-button: rgba(black, 0.26),
		disabled-text: $dark-disabled-text,
		hint-text: $dark-disabled-text,
		secondary-text: $dark-secondary-text,
		icon: rgba(black, 0.54),
		icons: rgba(black, 0.54),
		text: rgba(black, 0.87),
		slider-min: rgba(black, 0.87),
		slider-off: rgba(black, 0.26),
		slider-off-active: rgba(black, 0.38),
	),
	background: (
		status-bar: map_get(mat.$grey-palette, 300),
		app-bar: map_get(mat.$grey-palette, 100),
		background: map_get(mat.$grey-palette, 50),
		hover: rgba(black, 0.04), // TODO(kara): check style with Material Design UX
		card: white,
		dialog: white,
		disabled-button: rgba(black, 0.12),
		raised-button: white,
		focused-button: $dark-focused,
		selected-button: map_get(mat.$grey-palette, 300),
		selected-disabled-button: map_get(mat.$grey-palette, 400),
		disabled-button-toggle: map_get(mat.$grey-palette, 200),
		unselected-chip: map_get(mat.$grey-palette, 300),
		disabled-list-option: map_get(mat.$grey-palette, 200),
	)
);

//mat-light-theme($angular-app-primary, $angular-app-accent, $angular-app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-legacy-component-themes($angular-app-theme);
